import React, { Suspense } from "react"

import { isSSR } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"

const LoanType = React.lazy(() => import("../components/LoanType"))

export default () => {
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title="Tipo de préstamo"
            keywords={["Iban", "Online"]}
            description="Iban Online"
          />
          <Layout>
            <LoanType />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
